document.addEventListener('findstrLoaded', () => {
  const findstr = window.findstr || {};

  findstr.hooks.addFilter(
    'findstrEmptyResultsData',
    'findstr-search',
    (data) => {
      data.empty_result_img =
        findstr.translations.search_empty_results.empty_result_img;
      data.empty_results_text_1 =
        findstr.translations.search_empty_results.empty_results_text_1;
      data.empty_results_text_2 =
        findstr.translations.search_empty_results.empty_results_text_2;
      data.empty_most_searched_label =
        findstr.translations.search_empty_results.empty_most_searched_label;
      data.empty_most_searched_items =
        findstr.translations.search_empty_results.empty_most_searched_items;

      return data;
    }
  );
});
