import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  enableSwiper();

  window.addEventListener('resize', enableSwiper);

  function enableSwiper() {
    const breadcrumbs = document.querySelector('.breadcrumbs-carousel');

    if (!breadcrumbs) {
      return;
    }

    const navWidth = breadcrumbs.offsetWidth;
    const children = breadcrumbs.querySelectorAll(
      '.breadcrumbs-carousel__item'
    );
    const childrenWidth = Array.from(children).reduce(
      (acc, child) => acc + child.offsetWidth,
      0
    );
    if (navWidth < childrenWidth) {
      const breadcrumbLinks = document.querySelectorAll(
        '.breadcrumbs-carousel__item__link'
      );

      if (0 < breadcrumbLinks.length) {
        const breadcrumbsCarousel = new Swiper(breadcrumbs, {
          slidesPerView: 'auto',
          spaceBetween: 4,
          watchOverflow: true,
          a11y: {
            slideRole: null,
          },
          on: {
            sliderMove: (swiper) => {
              if (swiper.isBeginning) {
                swiper.el.classList.add('reached-start');
              } else {
                swiper.el.classList.remove('reached-start');
              }
              if (swiper.isEnd) {
                swiper.el.classList.add('reached-end');
              } else {
                swiper.el.classList.remove('reached-end');
              }
            },
          },
        });

        breadcrumbLinks.forEach((link) => {
          link.addEventListener('focus', () => {
            const slide = link.closest('.swiper-slide');
            const slideIndex = Array.prototype.indexOf.call(
              breadcrumbs.querySelector('.swiper-wrapper').children,
              slide
            );
            breadcrumbsCarousel.slideTo(slideIndex);
            if (breadcrumbsCarousel.isBeginning) {
              breadcrumbsCarousel.el.classList.add('reached-start');
            } else {
              breadcrumbsCarousel.el.classList.remove('reached-start');
            }
            if (breadcrumbsCarousel.isEnd) {
              breadcrumbsCarousel.el.classList.add('reached-end');
            } else {
              breadcrumbsCarousel.el.classList.remove('reached-end');
            }
          });
        });
      }
    }
  }
});
