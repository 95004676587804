import domReady from '@wordpress/dom-ready';

domReady(() => {
  const dropdowns = document.querySelectorAll(
    '.nav-desktop > .nav-item.dropdown'
  );
  const navWrapper = document.querySelector('.navs-container');

  function openDropdown(dropdown) {
    const submenu = dropdown.querySelector('.subnav-wrapper-depth-0');
    const toggle = dropdown.querySelector(
      '.dropdown__toggle[aria-controls="' + submenu.getAttribute('id') + '"]'
    );

    dropdown.classList.add('opened');
    toggle.setAttribute('aria-expanded', 'true');
    toggle.setAttribute('aria-label', toggle.getAttribute('data-label-close'));
  }

  function closeDropdown(dropdown) {
    const submenu = dropdown.querySelector('.subnav-wrapper-depth-0');
    const toggle = dropdown.querySelector(
      '.dropdown__toggle[aria-controls="' + submenu.getAttribute('id') + '"]'
    );

    dropdown.classList.remove('opened');
    toggle.setAttribute('aria-expanded', 'false');
    toggle.setAttribute('aria-label', toggle.getAttribute('data-label-open'));
  }

  if (0 < dropdowns.length) {
    dropdowns.forEach((dropdown) => {
      const submenu = dropdown.querySelector('.subnav-wrapper-depth-0');
      const closeBtn = dropdown.querySelector(
        '.dropdown__subnav-wrapper__close'
      );
      const toggle = dropdown.querySelector(
        '.dropdown__toggle[aria-controls="' + submenu.getAttribute('id') + '"]'
      );
      toggle.addEventListener('click', () => {
        dropdowns.forEach((otherDropdown) => {
          if (otherDropdown === dropdown) {
            if (dropdown.classList.contains('opened')) {
              closeDropdown(dropdown);
            } else {
              openDropdown(dropdown);
            }
          } else {
            closeDropdown(otherDropdown);
          }
        });
      });
      closeBtn.addEventListener('click', () => {
        closeDropdown(dropdown);
      });
    });
  }

  if (navWrapper) {
    navWrapper.addEventListener('scroll', () => {
      if (navWrapper.scrollTop > 10) {
        document
          .querySelector('#mobile-menu')
          .classList.add('desktop-scrolled');
      } else {
        document
          .querySelector('#mobile-menu')
          .classList.remove('desktop-scrolled');
      }
    });
  }
});
