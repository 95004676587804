import { _x } from '@wordpress/i18n';

document.addEventListener('findstrLoaded', () => {
  const findstr = window.findstr;
  const fortFields = document.querySelectorAll('.findstr-field-sort');

  if (findstr && 0 < fortFields.length) {
    findstr.hooks.addFilter(
      'findstrSortLabel',
      'lavalSort',
      (sortLabel, option, direction) => {
        let label = sortLabel;

        /* eslint-disable */
        switch (option) {
          case 'post_date':
            if ('asc' === direction) {
              label = _x('Least recent', 'sort by option label', 'vtx');
            } else {
              label = _x('Most recent', 'sort by option label', 'vtx');
            }
            break;
          case 'post_title':
            if ('asc' === direction) {
              label = _x('Title (A-Z)', 'sort by option label', 'vtx');
            } else {
              label = _x('Title (Z-A)', 'sort by option label', 'vtx');
            }
            break;
        }
        /* eslint-enable */

        return label;
      }
    );
  }
});
