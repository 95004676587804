import domReady from '@wordpress/dom-ready';

domReady(() => {
  // Edit : Added the target DialogInsightFormSubmit in the querySelectorALl, for the button style in the newsletter in the footer
  const wpButtons = document.querySelectorAll(
    '.wp-block-button > .wp-element-button, .wp-block-file > .wp-element-button, .DialogInsightFormSubmit > button[type="button"]'
  );

  if (0 < wpButtons.length) {
    wpButtons.forEach((wpButton) => {
      const btnText = wpButton.textContent;
      const inner = document.createElement('span');
      const content = document.createElement('span');
      const hover = document.createElement('span');
      inner.classList.add('btn-inner');
      content.classList.add('btn-content');
      hover.classList.add('btn-hover');
      hover.setAttribute('aria-hidden', 'true');
      content.textContent = btnText;
      hover.textContent = btnText;
      inner.appendChild(content);
      inner.appendChild(hover);
      wpButton.textContent = '';
      wpButton.appendChild(inner);
    });
  }
});
