import domReady from '@wordpress/dom-ready';

domReady(() => {
  const backBtn = document.querySelector('#back-to-top');
  if (!backBtn) {
    return;
  }
  const backTopLimit = screen.height * 1;
  const windowHeight = window.innerHeight;
  let timer = null;

  if (backBtn) {
    function checkBackBtnVisibility() {
      const st = window.scrollY;

      const yFooterPosition = document.querySelector('.site-footer').offsetTop;

      if (st <= backTopLimit) {
        document.body.classList.add('back-to-top-hidden');
      } else {
        document.body.classList.remove('back-to-top-hidden');
      }
      if (st + windowHeight >= yFooterPosition + 110) {
        document.body.classList.add('back-to-top-reachedFooter');
      } else {
        document.body.classList.remove('back-to-top-reachedFooter');
      }
    }

    backBtn.addEventListener('click', function () {
      document.getElementById('main-content').focus();
      window.scrollTo(0, 0);
    });

    checkBackBtnVisibility();

    window.addEventListener('scroll', () => {
      if (timer) {
        window.clearTimeout(timer);
      }

      timer = window.setTimeout(function () {
        // actual callback
        checkBackBtnVisibility();
      }, 50);
    });
  }
});
