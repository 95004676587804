document.addEventListener('findstrInit', function () {
  setTimeout(function () {
    const dropdowns = document.querySelectorAll(
      '.public-notices-listing__filters .tomselected',
      '.public-submissions-listing__filters .tomselected'
    );

    if (dropdowns.length > 0) {
      const monthOrder = {
        fr: [
          'janvier',
          'février',
          'mars',
          'avril',
          'mai',
          'juin',
          'juillet',
          'août',
          'septembre',
          'octobre',
          'novembre',
          'décembre',
        ],
        en: [
          'january',
          'february',
          'march',
          'april',
          'may',
          'june',
          'july',
          'august',
          'september',
          'october',
          'november',
          'december',
        ],
      };

      dropdowns.forEach((d) => {
        if (d.tomselect) {
          const options = d.tomselect.options;
          Object.keys(monthOrder).forEach((lang) => {
            let order = 1;
            monthOrder[lang].forEach((month) => {
              Object.values(options).forEach((option) => {
                if (option.text.toLowerCase() === month.toLowerCase()) {
                  option.$order = order;
                  option.value = month;
                  option.text = month;
                  order++;
                }
              });
            });
          });
        }
        d.tomselect.on('focus', function () {
          const nextSibling = d.nextElementSibling;
          setTimeout(function () {
            const dropdownContent = nextSibling.querySelector(
              '.ts-dropdown .ts-dropdown-content'
            );
            if (dropdownContent) {
              dropdownContent.scrollTo({ top: 0, behavior: 'instant' });
            }
          }, 0);
        });
      });
    }
  }, 500);
});
