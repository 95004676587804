document.addEventListener('findstrLoaded', () => {
  const findstr = window.findstr || {};

  findstr.hooks.addFilter(
    'findstrDatepickerOptions',
    'findstr-datepicker-laval',
    (options, field, group) => {
      //if the field is an event date, set the min date to today
      if ('events' === group) {
        options.minDate = new Date();
      }

      return options;
    }
  );

  findstr.hooks.addFilter(
    'urlManagementDisabled',
    'findstr-search',
    (disabled, group) => {
      const disabledGroups = ['hp-header', 'modal-search'];
      if (disabledGroups.includes(group)) {
        disabled = true;
      }
      return disabled;
    }
  );
});
