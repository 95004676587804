import domReady from '@wordpress/dom-ready';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const sharebox = document.querySelector('#share-box');

  if (sharebox) {
    const toggle = sharebox.querySelector('#share-box-toggle');
    const toggleLabel = sharebox.querySelector('#share-box-toggle-label');

    toggle.addEventListener('click', () => {
      if (sharebox.classList.contains('opened')) {
        sharebox.classList.remove('opened');
        toggle.setAttribute('aria-expanded', 'false');
        toggleLabel.textContent = _x(
          'See share options',
          'share toolbox dropdown toggle label',
          'vtx'
        );
      } else {
        sharebox.classList.add('opened');
        toggle.setAttribute('aria-expanded', 'true');
        toggleLabel.textContent = _x(
          'Hide share options',
          'share toolbox dropdown toggle label',
          'vtx'
        );
      }
    });

    document.addEventListener('click', (e) => {
      if (
        e.target !== sharebox &&
        e.target !== toggle &&
        sharebox.classList.contains('opened')
      ) {
        closeSharebox();
      }
    });

    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && sharebox.classList.contains('opened')) {
        closeSharebox();
      }
    });

    function closeSharebox() {
      sharebox.classList.remove('opened');
      toggle.setAttribute('aria-expanded', 'false');
      toggleLabel.textContent = _x(
        'See share options',
        'share toolbox dropdown toggle label',
        'vtx'
      );
    }
  }
});
