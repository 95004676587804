import domReady from '@wordpress/dom-ready';

function applyResponsiveBehavior() {
  const isMobile = window.matchMedia('(max-width: 767px)').matches;

  const linkWrappers = document.querySelectorAll(
    '.site-footer__link-content li.dropdown.nav-item--heading > .nav-item__link-wrapper'
  );

  linkWrappers.forEach((wrapper) => {
    const subnav = wrapper.nextElementSibling;

    // Delete mobile behavior
    if (wrapper.hasAttribute('data-toggle-bound')) {
      wrapper.removeEventListener('click', toggleDropdown);
      wrapper.removeAttribute('data-toggle-bound');
    }

    if (isMobile) {
      // Add mobile behavior
      wrapper.addEventListener('click', toggleDropdown);
      wrapper.setAttribute('data-toggle-bound', 'true');
      subnav.style.display = 'none';
    } else {
      // Remove mobile behavior
      wrapper.classList.remove('active');
      subnav.style.display = '';
      wrapper.style.cursor = 'default';
      wrapper.style.pointerEvents = 'none';
    }
  });

  if (isMobile) {
    // Remove desktop behavior
    linkWrappers.forEach((title) => {
      title.style.cursor = '';
      title.style.pointerEvents = '';
    });
  }
}

// Mobile
function toggleDropdown(event) {
  const wrapper = event.currentTarget;
  const subnav = wrapper.nextElementSibling;

  if (window.getComputedStyle(subnav).display === 'none') {
    subnav.style.display = 'block';
    wrapper.classList.add('active');
  } else {
    subnav.style.display = 'none';
    wrapper.classList.remove('active');
  }
}

domReady(() => {
  applyResponsiveBehavior();
  window.addEventListener('resize', applyResponsiveBehavior);
});
