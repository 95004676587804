import domReady from '@wordpress/dom-ready';

domReady(() => {
  const copyrights = document.querySelectorAll('.image-copyright');

  if (0 < copyrights.length) {
    copyrights.forEach((copyright) => {
      copyright.addEventListener('click', () => {
        copyright.classList.toggle('copyright-visible');
      });
    });
  }
});
