import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const carousel = document.querySelector('.page-header-3__carousel');

  if (carousel) {
    const slides = carousel.querySelectorAll('.swiper-slide');
    const args = {
      init: false,
      slidesPerView: 1,
      watchOverflow: true,
      pagination: {
        el: carousel.querySelector('.swiper-pagination'),
        clickable: true,
      },
      a11y: {
        firstSlideMessage: _x('This is the first slide', 'swiper label', 'vtx'),
        lastSlideMessage: _x('This is the last slide', 'swiper label', 'vtx'),
        prevSlideMessage: _x('Go to previous slide', 'swiper label', 'vtx'),
        nextSlideMessage: _x('Go to next slide', 'swiper label', 'vtx'),
        slideLabelMessage: _x(
          'Slide {{index}} of {{slidesLength}}',
          'swiper label',
          'vtx'
        ),
        paginationBulletMessage: _x(
          'Go to slide {{index}}',
          'swiper label',
          'vtx'
        ),
      },
    };

    if (1 < slides.length) {
      args.loop = true;
    } else {
      args.loop = false;
    }

    const swiper = new Swiper(carousel, args);

    swiper.on('init', (instance) => {
      const currentSlide = instance.slides[instance.activeIndex];
      const bullets = carousel.querySelectorAll('.swiper-pagination-bullet');
      const color = currentSlide.querySelector('.slide-color');
      bullets.forEach((bullet) => {
        bullet.style.backgroundColor = color.getAttribute('data-slide-color');
      });

      if (instance.slides.length === 1) {
        carousel.classList.add('single-slide');
      } else {
        carousel.classList.remove('single-slide');
      }
    });

    swiper.on('slideChange', (instance) => {
      const currentSlide = instance.slides[instance.activeIndex];
      const bullets = carousel.querySelectorAll('.swiper-pagination-bullet');
      const color = currentSlide.querySelector('.slide-color');
      bullets.forEach((bullet) => {
        bullet.style.backgroundColor = color.getAttribute('data-slide-color');
      });

      if (instance.slides.length === 1) {
        carousel.classList.add('single-slide');
      } else {
        carousel.classList.remove('single-slide');
      }
    });

    swiper.init();
  }
});
