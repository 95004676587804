import domReady from '@wordpress/dom-ready';

domReady(() => {
  const sticky = 80;
  let timer = null;

  function stickyNavbar() {
    const st = window.scrollY;
    if (st >= sticky) {
      document.body.classList.add('sticky-navbar');
    } else {
      document.body.classList.remove('sticky-navbar');
    }
  }

  function homeNavbarSearch() {
    const navbarSearch = document.querySelector('.navbar__search');
    const homeHeaderSearch = document.querySelector('.header-hp__search');

    if (homeHeaderSearch && navbarSearch) {
      const homeHeaderSearchRect = homeHeaderSearch.getBoundingClientRect();

      if (homeHeaderSearchRect.bottom < 0) {
        navbarSearch.classList.add('navbar__search--visible');
      } else {
        navbarSearch.classList.remove('navbar__search--visible');
      }
    }
  }

  window.addEventListener('scroll', () => {
    if (timer) {
      window.clearTimeout(timer);
    }

    timer = window.setTimeout(function () {
      stickyNavbar();
      homeNavbarSearch();
    }, 10);
  });

  stickyNavbar();
  homeNavbarSearch();
});
