import * as Focus from '../common/focus';
import { _x, sprintf } from '@wordpress/i18n';

window.addEventListener('load', function () {
  const toggleSearch = document.querySelector('.toggleSearch');
  const searchModal = document.querySelector('#search-modal');
  const closeSearchBtns = document.querySelectorAll(
    '.search-close, .search-modal__overlay'
  );

  const microSiteModal = document.querySelector(
    '.microsite-page .search-modal-wrapper'
  );

  if (microSiteModal) {
    const hamburgerBtn = document.querySelector('.vtx-burger-container');
    const searchModalForm = document.querySelector('.search-modal__form');
    const alertBtn = document.getElementById('expand-alerts-btn');
    if (hamburgerBtn) {
      hamburgerBtn.addEventListener('click', function () {
        if (microSiteModal.classList.contains('opened')) {
          closeSearchModal();
        }
      });
    }
    if (alertBtn) {
      alertBtn.addEventListener('click', function () {
        if (microSiteModal.classList.contains('opened')) {
          closeSearchModal();
        }
      });
    }
    if (searchModalForm) {
      searchModalForm.addEventListener('submit', function (e) {
        e.preventDefault();
        window.location.href = searchModalForm.getAttribute('action');
      });
    }
  }

  function closeSearchModal() {
    searchModal.classList.remove('opened');
    toggleSearch.setAttribute('aria-expanded', 'false');
    closeSearchBtns.forEach((closeSearch) => {
      closeSearch.setAttribute('aria-expanded', 'false');
    });
    document.documentElement.classList.remove('lockedPosition');
    document.removeEventListener(`keydown`, function (e) {
      Focus.initTrapFocus(e, 'search-modal');
    });
    if (microSiteModal) {
      toggleSearch.querySelectorAll('.btn-icon').forEach((icon) => {
        icon.classList.add('icon-search');
        icon.classList.remove('icon-x');
      });
    }
  }

  function openSearchModal() {
    const openEvent = new Event('opensearchmodal');
    searchModal.classList.add('opened');
    toggleSearch.setAttribute('aria-expanded', 'true');
    closeSearchBtns.forEach((closeSearch) => {
      closeSearch.setAttribute('aria-expanded', 'true');
    });
    window.dispatchEvent(openEvent);
    setTimeout(() => {
      document.getElementById('modal-search_default-search').focus();
      document.documentElement.classList.add('lockedPosition');
    }, 150);
    document.addEventListener(`keydown`, function (e) {
      Focus.initTrapFocus(e, 'search-modal');
    });
    if (microSiteModal) {
      toggleSearch.querySelectorAll('.btn-icon').forEach((icon) => {
        icon.classList.remove('icon-search');
        icon.classList.add('icon-x');
      });
    }
  }

  if (toggleSearch) {
    toggleSearch.addEventListener('click', function () {
      if (searchModal.classList.contains('opened')) {
        closeSearchModal();
      } else {
        openSearchModal();
      }
    });
  }

  if (0 < closeSearchBtns.length) {
    closeSearchBtns.forEach((closeSearch) => {
      closeSearch.addEventListener('click', function () {
        if (searchModal.classList.contains('opened')) {
          closeSearchModal();
        }
      });
    });
  }
});

/* modal findstr */

document.addEventListener('findstrLoaded', () => {
  const findstr = window.findstr;
  const modalForm = document.querySelector('.search-modal__form');
  let firstFocus = true;

  if (findstr && modalForm) {
    findstr.hooks.addAction(
      'searchResults',
      'modal-search',
      (results, group) => {
        if ('modal-search' === group) {
          if ('' === results.query) {
            modalForm.classList.remove('dropdown-active');
          } else {
            modalForm.classList.add('dropdown-active');
          }
        }
      }
    );

    findstr.hooks.addAction(
      'afterSearchResults',
      'modal-search',
      (results, group) => {
        if ('modal-search' === group) {
          const resultsBtn = modalForm.querySelector(
            '.findstr-search-result-all-results'
          );
          const resultsBtnLabels = modalForm.querySelectorAll(
            '.findstr-search-result-all-results .btn-label'
          );

          if (resultsBtn) {
            let actionUrl = '';
            if (document.body.classList.contains('microsite-page')) {
              let micrositeHome = '';
              const micrositeHomeLink =
                document.querySelector('header').dataset.micrositeUrl;
              if (micrositeHomeLink) {
                micrositeHome = micrositeHomeLink;
              }
              actionUrl =
                micrositeHome +
                _x('search', 'action search form', 'vtx') +
                '/?findstr[q]=' +
                results.query +
                '&findstr[group]=microsite-search';

              modalForm.setAttribute('action', actionUrl);
            } else {
              actionUrl = window.theme.home_url + '?s=' + results.query;
            }

            resultsBtn.setAttribute(
              'aria-label',
              sprintf(
                /* translators: %s is number of results */
                _x('All results (%s)', 'findstr all results button', 'vtx'),
                results.totalHits
              )
            );
            resultsBtn.setAttribute('href', actionUrl);
          }

          if (0 < resultsBtnLabels.length) {
            resultsBtnLabels.forEach((resultBtnLabel) => {
              resultBtnLabel.textContent = sprintf(
                /* translators: %s is number of results */
                _x('All results (%s)', 'findstr all results button', 'vtx'),
                results.totalHits
              );
            });
          }
        }
      }
    );

    modalForm.addEventListener('focusin', function () {
      if (firstFocus) {
        firstFocus = false;
      } else if (
        '' !== modalForm.querySelector('#modal-search_default-search').value
      ) {
        modalForm.classList.add('dropdown-active');
      } else {
        modalForm.classList.remove('dropdown-active');
      }
    });

    modalForm.addEventListener('focusout', function () {
      modalForm.classList.remove('dropdown-active');
    });
  }
});
