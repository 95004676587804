import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const mixedCarouselBlocks = document.querySelectorAll('.mixed-carousel');

  if (0 < mixedCarouselBlocks.length) {
    mixedCarouselBlocks.forEach((mixedCarouselBlock) => {
      new Swiper(mixedCarouselBlock.querySelector('.swiper'), {
        slidesPerView: 1.2,
        spaceBetween: 16,
        watchOverflow: true,
        loop: false,
        navigation: {
          prevEl: mixedCarouselBlock.querySelector('.swiper-button-prev'),
          nextEl: mixedCarouselBlock.querySelector('.swiper-button-next'),
        },

        breakpoints: {
          560: {
            slidesPerView: 1.7,
          },
          768: {
            slidesPerView: 2.25,
          },
          1080: {
            slidesPerView: 2.6,
          },
          1216: {
            slidesPerView: 'auto',
          },
        },

        a11y: {
          firstSlideMessage: _x(
            'This is the first slide',
            'swiper label',
            'vtx'
          ),
          lastSlideMessage: _x('This is the last slide', 'swiper label', 'vtx'),
          prevSlideMessage: _x('Go to previous slide', 'swiper label', 'vtx'),
          nextSlideMessage: _x('Go to next slide', 'swiper label', 'vtx'),
          slideLabelMessage: _x(
            'Slide {{index}} of {{slidesLength}}',
            'swiper label',
            'vtx'
          ),
          paginationBulletMessage: _x(
            'Go to slide {{index}}',
            'swiper label',
            'vtx'
          ),
        },
      });
    });
  }
});
