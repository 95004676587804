import * as Focus from '../common/focus';

(function ($) {
  let slideBarOpen = null;
  let slideBarClose = null;

  function toggleDropdown(event) {
    event.preventDefault();
    event.stopPropagation();

    const $toggle = $(this);
    const $li = $toggle.closest('.dropdown');
    const $subnav = $li.children('.dropdown__subnav-wrapper');

    // close all other dropdowns
    $li
      .siblings('.dropdown')
      .removeClass('opened')
      .children('.dropdown__subnav-wrapper')
      .slideUp('fast');

    if ($li.hasClass('opened')) {
      $li.removeClass('opened');
      $subnav.slideUp('fast');
    } else {
      $li.addClass('opened');
      $subnav.slideDown('fast');
    }
  }

  function prepareDropdownForNav($nav) {
    ///// INIT SLIDER POSITION /////
    $nav.find('.active').addClass('opened');

    $nav
      .find('.dropdown')
      .filter(':not(.opened)')
      .each(function () {
        $(this).children('.dropdown__subnav-wrapper').slideUp(0);
      });

    ///// INIT EVENTS /////
    $nav.find('.dropdown__toggle').on('click', toggleDropdown);
    $nav
      .find('.nav-item--heading > .nav-item__link-wrapper > .nav-item__link')
      .on('click', toggleDropdown);
  }

  function closeSlidebarMenu() {
    slideBarOpen.attr('aria-expanded', false);
    slideBarClose.attr('aria-expanded', false);
    $('body').removeClass('mobile-menu-open');
    $('html').removeClass('lockedPosition');
    Focus.untrapFocusInside($('#mobile-menu'));
    $(document).off('click.close_slidebar').off('keyup.close_slidebar');
    $('.nav-desktop .dropdown.opened').removeClass('opened');
  }

  function tryClosingSlidebarMenu(event) {
    // Keyboard
    if ('undefined' !== typeof event.keyCode) {
      // ESCAPE
      if (27 === event.keyCode) {
        $('.vtx-burger-container').focus();
        closeSlidebarMenu();
      }
    }
    // Click
    else if (!$(event.target).closest('#mobile-menu-wrapper').length) {
      closeSlidebarMenu();
    }
  }

  function openSlidebarMenu() {
    slideBarOpen.attr('aria-expanded', true);
    slideBarClose.attr('aria-expanded', true);
    $('body').addClass('mobile-menu-open');
    $('html').addClass('lockedPosition');
    Focus.trapFocusInside($('#mobile-menu'));
    $(document)
      .on('click.close_slidebar', tryClosingSlidebarMenu)
      .on('keyup.close_slidebar', tryClosingSlidebarMenu);
  }

  // On click outside the menu close it
  document.addEventListener('click', function (event) {
    const mobileMenu = document.getElementById('mobile-menu');
    if (mobileMenu) {
      if (!mobileMenu.contains(event.target)) {
        closeSlidebarMenu();
      }
    }
  });

  function initMenuMobile() {
    slideBarOpen = $('.vtx-burger-container');
    slideBarClose = $('.vtx-burger-close');

    const $mobileMenu = $('#mobile-menu');

    slideBarOpen.on('click', function (event) {
      event.stopPropagation();

      if (!$('body').hasClass('mobile-menu-open')) {
        openSlidebarMenu();
      }
    });

    slideBarClose.on('click', function (event) {
      event.stopPropagation();

      if ($('body').hasClass('mobile-menu-open')) {
        closeSlidebarMenu();
      }
    });

    prepareDropdownForNav($mobileMenu.find('.nav-mobile'));
  }

  $(document).ready(function () {
    initMenuMobile();
  });
})(jQuery);
