/* *
 * ========================================================================
 * Vortex Solution
 * ========================================================================
 * */

// Includes the custom Modernizr build based on the configurations set in .modernizrrc
require('Modernizr'); // eslint-disable-line import/no-extraneous-dependencies
require('nodelist-foreach-polyfill');
require('element-qsa-scope');
require('./main/dropdown-menu');
require('./main/mobile-menu');
require('./main/user-is-tabbing');
require('./main/forms');
require('./main/search-modal');
require('./main/breadcrumbs');
require('./main/image-copyright');
require('./main/mixed-posts-carousel');
require('./main/findstr-dropdowns');
require('./main/findstr-dropdown-months');
require('./main/findstr-empty-results');
require('./main/findstr-hooks');
require('./main/share-toolbox');
require('./main/back-to-top-button');
require('./main/navbar');
require('./main/nav-desktop');
require('./main/buttons-markup');
require('./main/footer');
require('./main/page-header');
require('./main/sort-field');
