import $ from 'jquery';
import TomSelect from 'tom-select/dist/js/tom-select.complete.min.js';
import { _x } from '@wordpress/i18n';

let fieldsAreInitialized = false;

function initSelects() {
  const selects = document.querySelectorAll(
    'select.custom-select, .gform_wrapper .gfield--address select, .variations_form select, .woocommerce-ordering select, .facetwp-type-sort select'
  );

  if (0 < selects.length) {
    selects.forEach((select) => {
      new TomSelect(select, {
        create: false,
        maxItems: 1,
        controlInput: null,
      });
    });
  }
}

function changeFileUpload(formID) {
  let singleFileUploads;
  let multiFileUploads;
  if (formID) {
    const form = document.querySelector('form[data-formid="' + formID + '"');
    singleFileUploads = form.querySelectorAll('.gfield--fileupload-single');
    multiFileUploads = form.querySelectorAll('.gform_fileupload_multifile');
  } else {
    singleFileUploads = document.querySelectorAll('.gfield--fileupload-single');
    multiFileUploads = document.querySelectorAll('.gform_fileupload_multifile');
  }

  // single file uploads
  if (0 < singleFileUploads.length) {
    singleFileUploads.forEach(function (fileUpload) {
      const input = fileUpload.querySelector('input[type="file"]');
      const label = fileUpload.querySelector('.gfield_label');
      const noFileLabel = _x('No file selected', 'file upload', 'vtx');

      // create elements
      const wrapper = document.createElement('div');
      const removeBtn = document.createElement('button');
      const uploadBtn = document.createElement('p');
      const uploadBtnIcon = document.createElement('span');
      const uploadBtnLabel = document.createElement('span');
      const filePlaceholder = document.createElement('p');

      // add classes
      wrapper.classList.add('fake-upload');
      filePlaceholder.classList.add('fake-upload__file-placeholder');
      uploadBtn.classList.add('fake-upload__upload-btn');
      uploadBtnLabel.classList.add('fake-upload__upload-btn__label');
      uploadBtnIcon.classList.add('fake-upload__upload-btn__icon');
      uploadBtnIcon.classList.add('icon-upload');
      removeBtn.classList.add('fake-upload__remove-btn');

      // add text contents
      removeBtn.innerHTML =
        '<span class="sr-only">' +
        _x('Clear file', 'file upload', 'vtx') +
        '</span><span class="icon-x"></span>';
      uploadBtnLabel.textContent = _x('Select a file', 'file upload', 'vtx');
      filePlaceholder.textContent = input.value ? input.value : noFileLabel;

      // extra attributes
      removeBtn.setAttribute('type', 'button');

      // append elements
      uploadBtn.appendChild(uploadBtnIcon);
      uploadBtn.appendChild(uploadBtnLabel);
      wrapper.appendChild(uploadBtn);
      wrapper.appendChild(filePlaceholder);
      wrapper.appendChild(removeBtn);
      label.after(wrapper);

      input.addEventListener('change', function () {
        if (input.value) {
          const filename = input.value.replace(/^C:\\fakepath\\/i, '');
          filePlaceholder.textContent = filename;
          removeBtn.classList.add('visible');
        } else {
          filePlaceholder.textContent = noFileLabel;
          removeBtn.classList.remove('visible');
        }
      });

      input.addEventListener('focus', function () {
        wrapper.classList.add('has-focus');
      });

      input.addEventListener('blur', function () {
        wrapper.classList.remove('has-focus');
      });

      removeBtn.addEventListener('click', function () {
        input.value = '';
        filePlaceholder.textContent = noFileLabel;
        removeBtn.classList.remove('visible');
      });
    });
  }

  // multiple file uploads
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      const gfRules = fileUpload.nextSibling;
      const icon = document.createElement('span');
      icon.classList.add('gform_drop_icon');
      icon.classList.add('icon-upload');
      fileUpload.querySelector('.gform_drop_area').appendChild(icon);

      if (gfRules && gfRules.classList.contains('gform_fileupload_rules')) {
        const rules = document.createElement('p');
        rules.classList.add('gform_drop_rules');
        rules.textContent = fileUpload.nextSibling.textContent;
        fileUpload.querySelector('.gform_drop_area').appendChild(rules);
      }

      fileUpload.querySelector('.gform_drop_instructions').textContent =
        wp.i18n._x(
          'Drag and drop file to upload',
          'drag and drop instruction',
          'vtx'
        );
    });
  }
}

$(document).on('gform_post_render', function (e, formID) {
  fieldsAreInitialized = true;
  changeFileUpload(formID);
  initSelects();
});

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
    initSelects();
  }
});
